import Vue from "vue";
import {ServiceEntity, ServiceRouteEntity} from "@/core/entity/service/service";
import display from "@/configure/display/service/service";
import {PropValidator} from "vue/types/options";
import {UserEntity, UserTeamEntity} from "@/core/entity/core/user";
import service from "@/core/service";
import {CompanyEntity} from "@/core/entity/core/company";
import {TeamEntity} from "@/core/entity/core/team";
import {Route} from "vue-router";
import {RouteEntity, RoutePortEntity} from "@/core/entity/service/route";
import tip from "@/configure/tip";
import {CountryEntity} from "@/core/entity/common/country";
import {PortEntity} from "@/core/entity/common/port";


export default Vue.extend({
    name: "service-info-service",
    model: {prop: 'data'},
    props: {
        data: {type: Object} as PropValidator<ServiceEntity>,
        display: {type: Object} as PropValidator<typeof display>,
        readonly: {type: Boolean},
        items: {},

    },
    data: () => ({
        show: true,
        userList: [] as Array<UserEntity>,
        operatorUserList:[] as Array<UserEntity>,
        operatorTeamList:[] as Array<UserTeamEntity>,
        companyList: [] as CompanyEntity[],
        teamList: [] as TeamEntity[],
        countryList: [] as CountryEntity[],
        countryRunning: 0,
        countryItem:<CountryEntity[]>[],
        countryChoose:false,
        statusInfoList:[
            {
                key:-1,
                name:'废弃'
            },
            {
                key:0,
                name:'停用'
            },
            {
                key:1,
                name:'正在服务'
            },
            {
                key:2,
                name:'服务完成'
            }
        ]
    }),
    async mounted() {
        const users = await service.user.query({status:1,hasCompany:true,hasUserOperator:true});
        this.userList=users.filter(p=>p.userType===0);
        this.operatorUserList=users.filter(p=>p.userType===1);
        this.operatorTeamList=await service.userTeam.query({});
        this.companyList = await service.company.query({});
        this.teamList = this.$user.teamList;
        this.countryList=await service.country.query({});
        this.countryItem=this.countryList;
    },
    methods: {
        input(value: number) {
            console.log(value);
        },
        getType(value: number){
            if(this.$route.name==="service.add")
                return "新增"
            for (let i = 0; i < this.statusInfoList.length; i++) {
                const item=this.statusInfoList[i];
                if(item.key===value)
                    return item.name
            }
        },
        async userChange(key: number) {
            const index = this.userList.findIndex(p => p.key === key);
            if (index >= 0) {
                const user = this.userList[index];
                this.data.userCompanyKey = user.companyKey;
            }
        },
        async userOperatorChange(key: number) {
            const index = this.operatorUserList.findIndex(p => p.key === key);
            if (index >= 0) {
                const user = this.operatorUserList[index];
                this.data.operatorUserTeamKey = user.teamKey;
            }
        },
        countryQuery(val: string, item: { querying: boolean } & ServiceEntity) {
            // console.log(val,item);
            if (item.querying)
                return;
            if (val === item.countryName)
                return;
            clearTimeout(this.countryRunning);
            // if (!val || val.length < 1)
            //     return;
            this.countryRunning = setTimeout(async () => {
                Vue.set(item, "querying", true);
                try {
                    this.countryItem = this.countryList.filter(p=>p.name!.indexOf(val)>=0);
                } finally {
                    Vue.set(item, "querying", false);
                }
            }, 50);
        },
        countryInput(country: CountryEntity, item: ServiceEntity) {
            item.countryKey = country?.key;
            item.countryName = country?.name;
        },
       async loadVesselName(){
           this.$emit("loadVesselName");
        },
        async loadServiceInfo(){
            this.$emit("loadServiceInfo");
        }

    },
    watch: {
    }
});
