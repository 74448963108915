import Vue from "vue";
import {ServiceEntity} from "@/core/entity/service/service";
import display from "@/configure/display/service/service";
import InfoService from "./info-service.vue";
import service from "@/core/service";
import InfoRoute from "./info-route.vue";
import InfoApply from "./info-apply.vue";
import {Verifiable} from "@/core/types/vuetify";
import {ApplyEntity} from "@/core/entity/apply/apply";

export default Vue.extend({
    components: {
        InfoService,
        InfoApply,
        InfoRoute,
    },
    data: () => ({
        key: void 0 as undefined | number,
        loading: false,
        readonly: false,
        saving: false,
        data: new ServiceEntity(),
        apply: new ApplyEntity(),
        display,
        loadOver: false
    }),
    async mounted() {
        switch (this.$route.name) {
            case "service.add":
                if(this.$user.teamList.length===1)
                    this.data.teamKey=this.$user.teamList[0].key;
                this.data.status = 1;
                this.data.teamKey=999;
                // this.data.routeList!.push({idx: 1}, {idx: 2});
                break;
            case "service.load":
                this.readonly = true;
            case "service.edit":
                this.key = Number(this.$route.params.key);
                await this.load();
                break;
        }
        this.loadOver = true

    },
    methods: {
        async load() {
            this.loading = true;
            try {
                const data = await service.service.load(this.key!);
                this.apply = await service.apply.load(data.applyKey!);
                if (!data.old)
                    data.old = {}
                // if (!data.routeList || data.routeList.length < 2)
                //     data.routeList = [{idx: 1}, {idx: 2}];
                this.data = data;
                await this.loadVesselName();
            } finally {
                this.loading = false;
            }
        },
        async save() {
            // if(this.$user.teamList.length!==1)
            //     this.$message("当前用户组信息不正确，不允许保存!", "error");
            if ((<Verifiable>this.$refs.form).validate()) {
                // if (this.data.routeList.length < 2) {
                //     this.$message("航路最少2个港口!", "error");
                //     return;
                // }
                this.saving = true;
                if (this.apply.key !== void 0) {
                    await service.apply.save(this.apply);
                    this.apply = await service.apply.load(this.apply.key!);
                } else {
                    this.apply.status = 1;
                    this.apply = await service.apply.add(this.apply);
                }
                try {
                    if (this.key === void 0) {
                        this.data.applyKey = this.apply.key;
                        // this.data.status=1;
                        this.data = await service.service.add(this.data);
                        this.key = this.data.key;
                    } else {
                        await service.service.save(this.data);
                        this.data = await service.service.load(this.key);
                    }
                    this.$message("保存成功!", "success");
                } finally {
                    this.saving = false;
                }
            } else
                this.$message("有数据填写错误!", "error");
        },
        async loadVesselName() {
            if (this.data.vesselKey !== void 0 && this.data.vesselKey > 10000000) {
                const aisList = await service.ais.infoBatch({mmsi: [this.data.vesselKey]});
                if (aisList !== void 0 && aisList.length > 0) {
                    this.$set(this.data, 'vesselName', aisList[0].name)
                } else
                    this.$set(this.data, 'vesselName', '查无数据');

            } else
                this.$set(this.data, 'vesselName', 'mmsi有错误！');
        },
        async loadServiceInfo(){
            if (this.data.old.serviceNo !== void 0 && this.data.old.serviceNo?.length > 4) {
                const oldService = await service.oldService.getOldServiceInfo({serviceNo: this.data.old.serviceNo});
                if (oldService !== void 0 && oldService.length>0) {
                    this.$set(this.data, 'vesselKey', oldService[0].mmsi);
                    await this.loadVesselName();
                } else
                    this.$set(this.data, 'vesselName', '查无数据');

            } else
                this.$set(this.data, 'serviceNo', '服务号有误！');
        }
    }
});
