export default {
    applyKey: "申请号",
    teamKey: "负责",
    vesselKey: "MMSI",
    customerKey: "customer key",
    status: "服务状态",
    createTime: "创建时间",
    operTime: "操作时间",
    userKey: "客户",
    userCompanyKey: "客户公司",
    operOperatorKey: "操作人主键",
    operOperatorName: "操作人",
    operatorUserKey: "业务员",
    operatorUserTeamKey: "业务组",
    old: {serviceNo: "服务号"},
    vesselName:"船名",
    routeName:"航线名称",
    countryKey:"国家代码",
    countryCode:"国籍",
    countryName:"国籍",
    route: {
        serviceKey: "服务主键",
        portKey: "港口主键",
        portName:"港口",
        country: "国家代码",
        eta: "ETA",
        etd: "ETD",
        loading: "loading status",
        instruction: "instruction",
        idx: "索引",
        ata: "ATA",
        atd: "ATD",
        distance: "里程",
        avgSog: "平均航速",
    },
    apply:{
        key:"主键",
        customerKey:"客户",
        companyKey:"公司"
    }

}
