import {UmEntity, UmQuery} from "../core";
import {ApplyEntity} from "@/core/entity/apply/apply";



/**
 * (Entity)
 * @author syj
 * @date 2021-09-22 10:03
 */
export class ServiceOldEntity extends UmEntity {
	//旧系统服务主键
	public serviceNo?: string;
}


/**
 * (Query)
 * @author syj
 * @date 2021-09-22 10:03
 */
export class ServiceOldQuery extends UmQuery {

}

/**
 * (Entity)
 * @author syj
 * @date 2021-09-22 10:03
 */
export class ServiceRouteEntity extends UmEntity {
	//服务主键
	public serviceKey?: number;
	//港口主键
	public portKey?: number;
	//country
	public country?: string;
	//eta
	public eta?: number;
	//etd
	public etd?: number;
	//loading status
	public loading?: number;
	//instruction
	public instruction?: string;
	//索引
	public idx?: number;

	public querying?:boolean;

	public ata?: number;
	public atd?:number;
	public distance?: number;
	public avgSog?: number;
	public portName?:string;
}


/**
 * (Query)
 * @author syj
 * @date 2021-09-22 10:03
 */
export class ServiceRouteQuery extends UmQuery {

	//服务主键
	public serviceKey?: number;
	//港口主键
	public portKey?: number;
}


/**
 * 服务(Entity)
 * @author syj
 * @date 2021-09-22 10:05
 */
export class ServiceEntity extends UmEntity {
	//service key
	public applyKey?: number;
	//team key
	public teamKey?: number;
	//vessel key(mmsi)
	public vesselKey?: number;
	//customer key
	public customerKey?: number;
	//服务状态 -1：废弃， 0：停用，1：正在服务，2：服务完成
	public status?: number;
	//前台用户（u_user)主键
	public userKey?: number;
	//前台用户公司主键
	public userCompanyKey?: number;
	//业务员主键
	public operatorUserKey?: number;
	//业务员组
	public operatorUserTeamKey?: number;
	//
	public old: ServiceOldEntity={

	};

	public vesselName?:string;
	//子表 List
	public routeList:ServiceRouteEntity[]=[];
	//航线主键
	public routeKey?:number;
	//航线名称
	public routeName?:string;

	private  apply?:ApplyEntity;

	//国家主键
	public countryKey?:number;
	//国家代码
	public countryCode?:string;
	//国家名称
	public countryName?:string;
	//国家代码
	public imgUrl?:string;

	public reportNum?:number;
}


/**
 * 服务(Query)
 * @author syj
 * @date 2021-09-22 10:05
 */
export class ServiceQuery extends UmQuery {

	//服务状态 -1：废弃， 0：停用，1：正在服务，2：服务完成
	public status?: number;
	//前台用户（u_user)主键
	public userKey?: number;
	//前台用户公司主键
	public userCompanyKey?: number;
	//组查询
	public teamKeyList?:Array<number>;
}


