import Vue from "vue";
import {ServiceEntity, ServiceRouteEntity} from "@/core/entity/service/service";
import displayService from "@/configure/display/service/service";
import {DataOptions, DataTableHeader} from "vuetify";
import {PropValidator} from "vue/types/options";
import service from "@/core/service";
import {PortEntity} from "@/core/entity/common/port";
import {RouteEntity, RoutePortEntity} from "@/core/entity/service/route";
import tip from "@/configure/tip";


export default Vue.extend({
    name: "service-info-route",
    model: {prop: 'data'},
    inject: ["form"],
    props: {
        data: {type: Object} as PropValidator<ServiceEntity>,
        display: {type: Object} as PropValidator<typeof displayService>,
        loadOver:{type: Boolean}
    },
    data() {
        return {
            show: true,
            headers: <DataTableHeader[]>[
                {text: this.display.route.idx, value: 'idx', sortable: false, width: 150, align: 'center'},
                {text: this.display.route.portName, value: 'portKey', type: 'input', align: 'center'},
                // {text: this.display.country, value: 'country', type: 'input', align: 'center'},

                {text: this.display.route.ata, value: 'ata', type: 'datetime', align: 'center'},
                {text: this.display.route.atd, value: 'atd', type: 'datetime', align: 'center'},
                {text: this.display.route.eta, value: 'eta', type: 'datetime', align: 'center'},
                {text: this.display.route.etd, value: 'etd', type: 'datetime', align: 'center'},
                // {text: this.display.loading, value: 'loading', type: 'input', align: 'center'},
                // {text: this.display.instruction, value: 'instruction', type: 'input', align: 'center'},
                {text: this.display.route.distance, value: 'distance', type: 'input', align: 'center'},
                {text: this.display.route.avgSog, value: 'avgSog', type: 'input', align: 'center'},
                {text: "", value: 'action', sortable: false, width: 150, align: 'center'},
            ],
            loadItem: [
                "Load",
                "Unload"
            ],
            options: <DataOptions><unknown>{sortBy: [], sortDesc: [], page: 1, itemsPerPage: -1},
            portLoading: false,
            portItems: <PortEntity[]>[],
            portRunning: 0,
            routeList: [] as RouteEntity[],
        }
    },
    methods: {
        add(index: number) {
            this.data.routeList.splice(index + 1, 0, {});
            for (let i = 0; i <this.data.routeList.length ; i++) {
                this.data.routeList[i].idx=i+1;
            }
        },
        del(index: number) {
            this.data.routeList.splice(index, 1);
            for (let i = 0; i <this.data.routeList.length ; i++) {
                this.data.routeList[i].idx=i+1;
            }
        },
        indexCompute(index: number, length: number) {
            if (index === 0)
                return "Departure Port";
            else if (index === length - 1)
                return "Destination Port";
            else
                return "Midway Port " + index;
        },
        portQuery(val: string, item: { querying: boolean } & ServiceRouteEntity) {
            if (item.querying)
                return;
            // if (val === item.port)
            //     return;
            clearTimeout(this.portRunning);
            if (!val || val.length < 2)
                return;
            this.portRunning = setTimeout(async () => {
                Vue.set(item, "querying", true);
                try {
                    this.portItems = await service.port.query({name: "%"+val + "%", sq: {size: 10}});
                } finally {
                    Vue.set(item, "querying", false);
                }
            }, 300);
        },
        portInput(port: PortEntity, item: ServiceRouteEntity) {
            item.portKey = port?.key;
            item.country = port?.countryCode;
            this.portItems = [];
        },
        async addToList(routePortList :Array<RoutePortEntity>){
            if (await this.$confirm(tip.confirm.addToList)) {
                for (let i = 0; i < routePortList.length; i++) {
                    const newRouteItem=new ServiceRouteEntity();
                    newRouteItem.portKey=routePortList[i].portKey;
                    newRouteItem.portName=routePortList[i].name;
                    newRouteItem.idx=this.data.routeList.length;
                    this.data.routeList.push(newRouteItem);
                }
            }
        },
    },
    computed: {
        readonly() {
            // @ts-ignore
            return !!this.form && this.form.readonly;
        }
    },
    watch:{
        'data.userCompanyKey'(newValue, oldValue){
            if (newValue != void 0 && newValue !== oldValue) {
                service.route.query({userCompanyKey: newValue}).then((list) => this.routeList = list);
            }
        },
        'data.routeKey'(newValue, oldValue){
            if (newValue != void 0 && newValue !== oldValue&&this.loadOver) {
                service.route.load(newValue).then((route) => {
                    if(route.portList!.length>0)
                        this.addToList(route.portList!);
                });
            }
        }
    }
});
