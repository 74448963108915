import Vue from "vue";
import {ApplyEntity, ApplyQuery} from "@/core/entity/apply/apply";
import service from "@/core/service";
import {PropValidator} from "vue/types/options";
import display from "@/configure/display/service/apply";

export default Vue.extend({
    name: "service-info-apply",
    model: {prop: 'data'},
    props: {
        data: {type: Object} as PropValidator<ApplyEntity>,
        display: {type: Object} as PropValidator<typeof display>,
        readonly: {type: Boolean},
    },
    data() {
        return {show: true,};
    },
    methods: {},
});
