import {UmEntity, UmQuery} from "../core";



/**
 * 业务申请(Entity)
 * @author syj
 * @date 2021-09-22 10:04
 */
export class ApplyEntity extends UmEntity {
	//status
	public status?: number;
	//用户主键
	public customerKey?: number;
	//公司主键
	public companyKey?: number;
}


/**
 * 业务申请(Query)
 * @author syj
 * @date 2021-09-22 10:04
 */
export class ApplyQuery extends UmQuery {

	//status
	public status?: number;
}
